import React from "react";
import CoursesListSeo from "@/SEO/CoursesList";
import CoursesPageView from "./CoursesPage.view";
import useAuthActions from "@/store/actions/authAction";
import PageBuilder, {
  getPageBuilderProps,
} from "@/mvcComponents/CommonUI/Sections/PageBuilder.controller";
import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";
import CoursesPageManager from "@/Logic/ClientLogic/Services/PageServices/CoursesPageManager";

const CoursesPageController = ({ pageData, customPageData }) => {
  ClientAppManager.instance.setPageManager(new CoursesPageManager());
  const { auth } = useAuthActions();

  const { seo = {}, faqs = [] } = pageData || {};
  const { coursesList = [] } = customPageData || {};

  const components = [
    {
      id: "CoursesListSeo",
      comp: CoursesListSeo,
      props: {
        metaData: seo,
        data: { coursesList, faqs },
      },
    },
    {
      id: "PageBuilder",
      comp: PageBuilder,
      props: getPageBuilderProps(pageData),
    },
  ];

  return <CoursesPageView components={components} isLoggedIn={auth?.token} />;
};

export default CoursesPageController;
